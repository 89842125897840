<template>
  <b-carousel
    ref="currentRef"
    controls
    :interval="0"
  >
    <b-carousel-slide
      v-for="(item, index) of slideOptions"
      :key="index"
      :img-src="$helpers.imageHelper(item)"
    />
  </b-carousel>
</template>

<script>
import { BCarousel, BCarouselSlide } from 'bootstrap-vue'

export default {
  components: {
    BCarousel,
    BCarouselSlide,
  },
  props: {
    slideOptions: {
      type: Array,
      default: () => [],
    },
    focusImage: {
      type: String,
      default: '',
    },
  },
  computed: {
    modalRef() {
      return this.$refs.currentRef
    },
  },
  watch: {
    focusImage: {
      handler() {
        this.setActiveImage()
      },
    },
  },
  methods: {
    setActiveImage() {
      if (this.focusImage) {
        const index = this.slideOptions.findIndex(x => x === this.focusImage)
        if (index >= 0) {
          this.setSlide(index)
        }
      }
    },
    prev() {
      this.$refs.currentRef.prev()
    },
    next() {
      this.$refs.currentRef.next()
    },
    setSlide(index) {
      this.$refs.currentRef.setSlide(index)
    },
  },
}
</script>
