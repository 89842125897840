<template>
  <div style="height: inherit" class="user-provider-store-details user-store-details store-ui px-md-2" :class="{ 'p-0': $store.getters['app/windowSize'] <= 767 }">
    <login-modal ref="loginModal" />
    <template v-if="$store.getters['app/windowSize'] > 767">
      <div class="store-ui mt-2">
        <div class="row">
          <div class="col-md-12 p-sm-0 d-flex flex-wrap">
            <b-row class="d-flex justify-content-between w-100">
              <b-col cols="12" :md="otherPhotos.length > 0 ? 6 : 12" class="pr-0">
                <b-img
                  class="left-image cursor-pointer border-radius-10"
                  :src="mainPhoto ? $helpers.imageHelper(mainPhoto) : require('@/assets/images/app-images/banner-store-image.webp')"
                  @click="
                    showPopup = true
                    showPopupImage($helpers.imageHelper(mainPhoto))
                  "
                  @error="$helpers.imageError"
                />
              </b-col>
              <b-col v-if="otherPhotos.length > 0" cols="12" md="6" class="d-flex justify-content-end pr-0">
                <b-row class="d-flex flex-wrap grid-row-gap-5">
                  <b-col cols="6" class="align-self-end px-0" v-for="(item, index) of otherPhotos.slice(0, 4)" :key="`${index}_other_images`">
                    <div class="pl-2">
                      <b-img :src="$helpers.imageHelper(item.photo)" @click="showPopupImage(item.photo)" class="right-side-images border-radius-10" @error="$helpers.imageError" />
                    </div>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
        </div>
        <b-card class="mt-3 w-100 main_card">
          <div class="row w-100">
            <div class="col-12">
              <div class="row d-flex align-items-center">
                <div class="col-12 col-md-8">
                  <h1 class="store_title d-flex align-items-center">
                    <b-img v-if="storeList.picture" :src="$helpers.imageHelper(storeList.picture)" @error="$helpers.imageError" fluid class="store-logo mr-1" />
                    <span>{{ storeList.company_name }}</span>
                  </h1>
                </div>
                <div class="col-12 col-md-4 d-flex justify-content-end">
                  <img v-for="star in 5" :key="star" class="profile_page_label_icon" :class="{ 'gray-scale-icon': star > storeList.rating }" :src="require('@/assets/images/app-icons/rating.png')" />
                  <img class="cursor-pointer ml-2 profile_page_label_icon" :src="require('@/assets/images/app-icons/Share.png')" @click="copyUrl" />
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="row">
                <div class="col-12 col-md-6">
                  <div v-if="storeList.mobile" class="my-2 d-flex justify-content-start">
                    <img :src="require('@/assets/images/app-icons/Call.png')" class="mr-2 profile_page_label_icon" />
                    <p class="store_phone fs-1p5rem cursor-pointer" @click="openPhone">+{{ storeList.country_code }}{{ storeList.mobile }}</p>
                  </div>
                  <div class="row my-2">
                    <div class="col-12 d-flex justify-content-between">
                      <div class="row d-flex align-content-center flex-wrap pl-1">
                        <div v-if="storeList.facebook" class="col-auto px-0 pr-1">
                          <img
                            class="profile_page_label_icon cursor-pointer"
                            :src="require('@/assets/images/icons/social/icons8-facebook.svg')"
                            @click="goToSocial(`${storeList.facebook}`, '_BLANK')"
                          />
                        </div>
                        <div v-if="storeList.instagram" class="col-auto px-0 pr-1">
                          <img
                            class="profile_page_label_icon cursor-pointer"
                            :src="require('@/assets/images/icons/social/icons8-instagram.svg')"
                            @click="goToSocial(`${storeList.instagram}`, '_BLANK')"
                          />
                        </div>
                        <div v-if="storeList.linkedin" class="col-auto px-0 pr-1">
                          <img
                            class="profile_page_label_icon cursor-pointer"
                            :src="require('@/assets/images/icons/social/icons8-linkedin.svg')"
                            @click="goToSocial(`${storeList.linkedin}`, '_BLANK')"
                          />
                        </div>
                        <div v-if="storeList.telegram" class="col-auto px-0 pr-1">
                          <img
                            class="profile_page_label_icon cursor-pointer"
                            :src="require('@/assets/images/icons/social/icons8-telegram-app.svg')"
                            @click="goToSocial(`${storeList.telegram}`, '_BLANK')"
                          />
                        </div>
                        <div v-if="storeList.twitter" class="col-auto px-0 pr-1">
                          <img class="profile_page_label_icon cursor-pointer" :src="require('@/assets/images/icons/social/icons8-twitter.png')" @click="goToSocial(`${storeList.twitter}`, '_BLANK')" />
                        </div>
                        <div v-if="storeList.whatsapp" class="col-auto px-0 pr-1">
                          <img
                            class="profile_page_label_icon cursor-pointer"
                            :src="require('@/assets/images/icons/social/icons8-whatsapp.svg')"
                            @click="goToSocial(`${storeList.whatsapp}`, '_BLANK')"
                          />
                        </div>
                        <div v-if="storeList.messenger" class="col-auto px-0 pr-1">
                          <img
                            class="profile_page_label_icon cursor-pointer"
                            :src="require('@/assets/images/icons/social/icons8-facebook-messenger.svg')"
                            @click="goToSocial(`${storeList.messenger}`, '_BLANK')"
                          />
                        </div>
                        <div v-if="storeList.tiktok" class="col-auto px-0 pr-1">
                          <img class="profile_page_label_icon cursor-pointer" :src="require('@/assets/images/icons/social/icons8-tiktok.svg')" @click="goToSocial(`${storeList.tiktok}`, '_BLANK')" />
                        </div>
                        <div v-if="storeList.viber" class="col-auto px-0 pr-1">
                          <img class="profile_page_label_icon cursor-pointer" :src="require('@/assets/images/icons/social/icons8-viber.svg')" @click="goToSocial(`${storeList.viber}`, '_BLANK')" />
                        </div>
                        <div v-if="storeList.youtube" class="col-auto px-0 pr-1">
                          <img class="profile_page_label_icon cursor-pointer" :src="require('@/assets/images/icons/social/icons8-youtube.svg')" @click="goToSocial(`${storeList.youtube}`, '_BLANK')" />
                        </div>
                        <div v-if="storeList.pintrest" class="col-auto px-0 pr-1">
                          <img
                            class="profile_page_label_icon cursor-pointer"
                            :src="require('@/assets/images/icons/social/icons8-pinterest.svg')"
                            @click="goToSocial(`${storeList.pintrest}`, '_BLANK')"
                          />
                        </div>
                        <div v-if="storeList.skype" class="col-auto px-0 pr-1">
                          <img class="profile_page_label_icon cursor-pointer" :src="require('@/assets/images/icons/social/icons8-skype.svg')" @click="goToSocial(`${storeList.skype}`, '_BLANK')" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="storeList.offer_min_amount" class="my-2 d-flex justify-content-start">
                    <img :src="require('@/assets/images/app-icons/shopping-bag.png')" class="mr-2 profile_page_label_icon" />
                    <p class="store_phone fs-1p5rem">{{ $t('Minimum order value') }} {{ storeList.currency_symbol }} {{ storeList.offer_min_amount }}</p>
                  </div>
                  <div v-if="storeTimings" class="my-2 d-flex justify-content-start">
                    <img :src="require('@/assets/images/app-icons/Timing.png')" class="mr-2 profile_page_label_icon" />
                    <p class="store_phone fs-1p5rem cursor-pointer" @click="toggleTimings = !toggleTimings">
                      Today: {{ storeTimings.start_time.slice(0, 5) }} - {{ storeTimings.end_time.slice(0, 5) }}
                      <feather-icon :icon="toggleTimings ? 'ChevronUpIcon' : 'ChevronDownIcon'" size="15" />
                    </p>
                  </div>
                  <div v-if="storeTimings && toggleTimings" class="my-2 d-flex justify-content-start ml-5">
                    <table class="table table-borderless">
                      <tbody>
                        <tr v-for="(data, index) of weekDays" :key="index">
                          <th>
                            {{ data }}
                          </th>
                          <td v-if="storeTimingData(data)">{{ storeTimingData(data).start_time.slice(0, 5) }} - {{ storeTimingData(data).end_time.slice(0, 5) }}</td>
                          <td v-else>Closed</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="w-100">
                    <p class="store_phone fs-1p5rem">{{ $t('Service is available at') }}</p>
                  </div>
                  <div class="my-1 d-flex justify-content-start">
                    <b-button v-if="storeList.onsite == 0 || storeList.onsite == 2" variant="outline-dark" class="mr-1 fs-1rem">
                      <b-img height="25" class="mr-1" :src="require('@/assets/images/app-icons/address/Work.png')" />
                      {{ $t('Provider') }}
                    </b-button>
                    <b-button v-if="storeList.onsite == 2" variant="outline-dark" class="mr-1 fs-1rem">
                      <b-img height="25" class="mr-1" :src="require('@/assets/images/app-icons/address/Home.png')" />
                      {{ $t('User') }}
                    </b-button>
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="row">
                    <div v-if="mapRoute" class="col-12">
                      <iframe :src="mapRoute" style="width: 100%; height: 300px; border: 0" />
                    </div>
                    <div class="col-12 mt-2">
                      <div class="d-flex align-items-center">
                        <b-img height="40" :src="`${$apiUrl}/images/icons/location.svg`" class="mr-1" />
                        <p class="store_location fs-1p5rem mb-0 cursor-pointer" @click="openStoreMap">
                          {{ storeList.current_location }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-card>
        <b-card class="mt-3 tab_card">
          <div class="store-ui-main-descriptions">
            <b-tabs pills class="user-profile-info d-flex flex-wrap justify-content-center mb-5 store-details-tabs">
              <b-tab active>
                <template #title>
                  <span class="d-inline">{{ $t('OVERVIEW') }}</span>
                </template>
                <no-data-available v-if="!optionsLoaded.overview" :type="'overview'" />
                <div v-if="optionsLoaded.overview && storeList.overviews" class="wrap-overview fs-18px">
                  <!-- eslint-disable vue/no-v-html -->
                  <p v-html="storeList.overviews.overview" />
                  <!--eslint-enable-->
                </div>
              </b-tab>
              <b-tab>
                <template #title>
                  <span class="d-inline">{{ $t('BOOK') }}</span>
                </template>
                <div class="wrap-online-order-section">
                  <no-data-available v-if="!optionsLoaded.products" :type="'product'" />
                  <div v-if="optionsLoaded.products" class="row">
                    <div class="col-md-12">
                      <section id="ecommerce-header">
                        <div class="row">
                          <div class="col-sm-12">
                            <div class="ecommerce-header-items">
                              <div class="result-toggler">
                                <feather-icon icon="MenuIcon" class="d-block d-lg-none" size="21" @click="mqShallShowLeftSidebar = true" />
                                <div class="search-results fs-16px">
                                  {{ $t('Showing') }} {{ filteredStore.length }} {{ $t('of') }} {{ storeList.provider_service ? storeList.provider_service.length : 0 }} {{ $t('results') }}
                                </div>
                              </div>
                              <div class="view-options d-flex">
                                <!-- Item View Radio Button Group  -->
                                <b-form-radio-group v-model="itemView" class="ml-1 list item-view-radio-group" buttons size="sm" button-variant="outline-primary">
                                  <b-form-radio v-for="option in itemViewOptions" :key="option.value" :value="option.value">
                                    <feather-icon :icon="option.icon" size="18" />
                                  </b-form-radio>
                                </b-form-radio-group>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>

                      <!-- Overlay -->
                      <div class="body-content-overlay" />

                      <!-- Searchbar -->
                      <div class="ecommerce-searchbar mt-1">
                        <b-row>
                          <b-col cols="12">
                            <b-input-group class="input-group-merge">
                              <b-form-input v-model="searchQuery" placeholder="Search..." class="search-product" @keyup="beginSearch" />
                              <b-input-group-append is-text>
                                <feather-icon icon="SearchIcon" class="text-muted" />
                              </b-input-group-append>
                            </b-input-group>
                          </b-col>
                        </b-row>
                      </div>

                      <!-- Prodcuts -->
                      <section :class="itemView">
                        <b-card v-for="product in filteredStore" :key="product.id" class="ecommerce-card pt-pb" no-body>
                          <div class="item-img text-center">
                            <figure @mouseenter="$superApp.helper.zoomMagic" @mouseout="$superApp.helper.zoomOut">
                              <v-zoomer :zoomed.sync="$superApp.zoomed" :mouse-wheel-to-zoom.sync="$superApp.mouseWheelToZoom">
                                <b-img
                                  :alt="!isEmpty(product.title) ? product.title : product.main_service.service_name"
                                  fluid
                                  class="card-img-top"
                                  :src="$helpers.imageHelper(product.main_service.picture)"
                                  @error="$helpers.imageError"
                                />
                              </v-zoomer>
                            </figure>
                          </div>

                          <!-- Product Details -->
                          <b-card-body>
                            <div class="item-wrapper">
                              <div class="item-rating" />
                              <div>
                                <h6 class="item-price fs-16px">{{ storeList.currency_symbol }} {{ product.base_fare }}</h6>
                              </div>
                            </div>
                            <h6 class="item-name fs-16px">
                              <b-link class="text-body">
                                {{ !isEmpty(product.title) ? product.title : product.main_service.service_name }}
                              </b-link>
                              <b-card-text class="item-company">
                                {{ $t('By') }} {{ storeList.company_name }}
                                <b-link class="ml-25"> {{ storeList.first_name }} {{ storeList.last_name }} </b-link>
                              </b-card-text>
                            </h6>
                            <b-card-text
                              v-if="getServiceDescription(product)"
                              v-b-tooltip.hover
                              class="item-description auto-text fs-14px"
                              :class="{ 'mb-0': itemView === 'list-view' }"
                              :title="getServiceDescription(product)"
                            >
                              {{ product.showFull ? getServiceDescription(product) : getText(getServiceDescription(product)) }}
                              <span
                                v-if="canShowLabel(getServiceDescription(product))"
                                class="small font-weight-bold text-right fs-14px"
                                style="word-break: keep-all !important"
                                @click="product.showFull = !product.showFull"
                              >
                                <feather-icon :icon="product.showFull ? 'ChevronUpIcon' : 'ChevronDownIcon'" class="cursor-pointer" size="15" />
                              </span>
                            </b-card-text>
                          </b-card-body>
                          <div class="item-options text-center list-view-manage">
                            <b-button v-if="isAddedToCart(product)" :variant="'primary'" class="cart-item-qty btn-cart">
                              <b-form-spinbutton v-model="addedProduct(product).quantity" min="0" size="sm" @change="addItemToCart(product)" />
                            </b-button>
                            <b-button v-if="!isAddedToCart(product)" variant="primary" tag="a" class="btn-cart" @click="addItemToCart(product)">
                              <span>{{ isAddedToCart(product) ? 'Added to cart' : 'Add' }}</span>
                            </b-button>
                          </div>
                        </b-card>
                      </section>
                    </div>
                  </div>
                </div>
              </b-tab>
              <b-tab>
                <template #title>
                  <span class="d-inline">{{ $t('REVIEW') }}</span>
                </template>
                <no-data-available v-if="!optionsLoaded.reviews" :type="'reviews'" />
                <div v-if="optionsLoaded.reviews" class="wrap-reviews-info">
                  <div class="d-flex">
                    <div v-if="storeList.reviews" class="col-md-8 store-shadow wrap-store-review">
                      <h3 class="mt-2">
                        {{ $t('All reviews') }}
                      </h3>
                      <div v-for="(item, index) in storeList.reviews" :key="index" class="row reviews pt-2 pb-0" :class="{ 'border-bottom': index < storeList.reviews.length }">
                        <div class="col-md-9">
                          <b-media class="pb-50 pt-50 align-items-center">
                            <template #aside>
                              <b-avatar />
                            </template>
                            <h6 class="mb-0">
                              {{ item.user ? `${item.user.first_name} ${item.user.last_name}` : '' }}
                              <span v-if="item.request" class="ml-1 font-weight-bolder"> ({{ $t('Service No: ') }} {{ item.request.booking_id }}) </span>
                            </h6>
                            <p class="small mb-0">{{ $t('Comment on:') }} {{ formatDate(item.created_at) }}</p>
                          </b-media>
                          <p class="mt-2">
                            {{ item.review }}
                          </p>
                        </div>
                        <div class="col-md-3 d-flex flex-wrap align-items-center justify-content-center">
                          <div>
                            <h2 class="text-center">
                              {{ item.rate }}
                            </h2>
                            <div class="mb-0">
                              <ul class="unstyled-list list-inline">
                                <li v-for="star in 5" :key="star" class="ratings-list-item" :class="{ 'ml-25': star - 1 }">
                                  <feather-icon icon="StarIcon" size="16" :class="[{ 'fill-current': star <= item.rate }, star <= item.rate ? 'theme-color' : 'text-muted']" />
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div v-if="item.review_response" class="col-md-9">
                          <div class="ml-5">
                            <b-media class="pb-50 pt-50 align-items-center">
                              <template #aside>
                                <b-avatar />
                              </template>
                              <h6 v-if="storeList" class="mb-0">
                                {{ storeList.store_name }}
                              </h6>
                              <p class="small mb-0">{{ $t('Comment on:') }} {{ formatDate(item.review_response.created_at) }}</p>
                            </b-media>
                            <p class="mt-2">
                              {{ item.review_response.review }}
                            </p>
                          </div>
                        </div>
                        <div v-if="item.review_response" class="col-md-3 d-flex flex-wrap align-items-center justify-content-center">
                          <div>
                            <h2 class="text-center">
                              {{ item.review_response.rate }}
                            </h2>
                            <div class="mb-0">
                              <ul class="unstyled-list list-inline">
                                <li v-for="star in 5" :key="star" class="ratings-list-item" :class="{ 'ml-25': star - 1 }">
                                  <feather-icon
                                    icon="StarIcon"
                                    size="16"
                                    :class="[{ 'fill-current': star <= item.review_response.rate }, star <= item.review_response.rate ? 'theme-color' : 'text-muted']"
                                  />
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-if="lastReview" class="col-md-3 store-shadow px-2 py-2 store-left-margin">
                      <div class="recent-reviews">
                        <div class="d-flex justify-content-between align-items-center">
                          <h2>{{ $t('Newest') }}</h2>
                          <div class="wrap-next-prev">
                            <feather-icon class="cursor-pointer" icon="ChevronLeftIcon" @click="prevReview" />
                            <feather-icon class="cursor-pointer" icon="ChevronRightIcon" @click="nextReview" />
                          </div>
                        </div>
                        <p class="mt-2">“{{ lastReview.review }}”</p>
                        <b-media class="pb-50 pt-50 mt-2 align-items-center">
                          <template #aside>
                            <b-avatar />
                          </template>
                          <h6>{{ lastReview.user ? `${lastReview.user.first_name} ${lastReview.user.last_name}` : '' }}</h6>
                        </b-media>
                        <div class="col-md-12 d-flex align-items-center justify-content-center latest-review-wrapper">
                          <div class="w-100 d-flex align-items-center">
                            <h2 class="text-center mb-0 mr-1 text-white">
                              {{ lastReview.rate }}
                            </h2>
                            <div class="mb-0">
                              <ul class="unstyled-list list-inline mb-0">
                                <li v-for="star in 5" :key="star" class="ratings-list-item" :class="{ 'ml-25': star - 1 }">
                                  <feather-icon icon="StarIcon" size="16" :class="[{ 'fill-current': star <= lastReview.rate }, star <= lastReview.rate ? 'text-white' : 'text-muted']" />
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div class="d-flex">
                <div class="col-md-8 store-shadow wrap-store-review mt-3">
                  <post-review />
                </div>
              </div> -->
              </b-tab>
              <b-tab>
                <template #title>
                  <span class="d-inline">{{ $t('PHOTOS') }}</span>
                </template>
                <no-data-available v-if="!optionsLoaded.photos" :type="'photos'" />
                <div v-if="otherPhotos && otherPhotos.length > 0 && optionsLoaded.photos" class="d-grid grid-mobile-columns">
                  <figure v-for="(item, index) in otherPhotos" :key="index" class="store-shadow" @mouseenter="$superApp.helper.zoomMagic" @mouseout="$superApp.helper.zoomOut">
                    <v-zoomer :zoomed.sync="$superApp.zoomed" :mouse-wheel-to-zoom.sync="$superApp.mouseWheelToZoom">
                      <b-img
                        class="store-photos-tab store-shadow img-transition cursor-pointer"
                        data-hide="true"
                        :src="$helpers.imageHelper(item.photo)"
                        @click="showPopup = true"
                        @error="$helpers.imageError"
                      />
                    </v-zoomer>
                  </figure>
                </div>
              </b-tab>
              <b-tab>
                <template #title>
                  <span class="d-inline">{{ $t('MENU') }}</span>
                </template>
                <no-data-available v-if="!optionsLoaded.menu" :type="'menu'" />
                <div v-if="storeList.photos && optionsLoaded.menu" class="d-grid grid-mobile-columns">
                  <figure
                    v-for="(item, index) in storeList.photos.filter(x => x.is_menu === 1)"
                    :key="index"
                    class="store-shadow"
                    @mouseenter="$superApp.helper.zoomMagic"
                    @mouseout="$superApp.helper.zoomOut"
                  >
                    <v-zoomer :zoomed.sync="$superApp.zoomed" :mouse-wheel-to-zoom.sync="$superApp.mouseWheelToZoom">
                      <b-img
                        class="store-photos-tab store-shadow img-transition cursor-pointer"
                        data-hide="true"
                        :src="$helpers.imageHelper(item.photo)"
                        @click="showPopup = true"
                        @error="$helpers.imageError"
                      />
                    </v-zoomer>
                  </figure>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </b-card>
      </div>
    </template>
    <provider-store-details-responsive v-if="$store.getters['app/windowSize'] <= 767" :that="that" />
    <b-modal id="slider-modal" v-model="showPopup" :no-close-on-backdrop="true" hide-footer @hidden="showPopup = false">
      <modal-carousel ref="modalRef" :slide-options="allPhotos" />
    </b-modal>
    <div class="text-center">
      <div class="d-block">
        <p v-if="storeList.tax_id" class="mb-0">Tax Id: {{ storeList.tax_id }}</p>
        <p v-if="storeList.registration_id" class="mb-0">Registration No: {{ storeList.registration_id }}</p>
      </div>
      <p class="mb-0 mt-0 p-0">
        <b-link :to="{ path: termsConditions }">{{ $t('terms and conditions') }}</b-link>
        |
        <b-link to="/privacy-policy">{{ $t('Privacy Policy') }}</b-link>
      </p>
    </div>
  </div>
</template>

<script>
import { hideLoader, showDangerNotification, showErrorNotification, showLoader, showSuccessNotification, useResponsiveAppLeftSidebarVisibility } from '@/@core/comp-functions/ui/app'
// import DirectionsRenderer from '@/libs/DirectionsRenderer'
import LoginModal from '@core/layouts/components/app-navbar/components/LoginModal.vue'
import { isUserLoggedIn } from '@/auth/utils'
// import PostReview from '@/views/shared/PostReview.vue'
import moment from 'moment'
import { useUserUi } from './useUser'
import NoDataAvailable from './shared/NoDataAvailable.vue'
import ModalCarousel from './shared/ModalCarousel.vue'
import ProviderStoreDetailsResponsive from './ProviderStoreDetailsResponsive.vue'
import { isEmpty } from '@/utilities'

export default {
  components: {
    LoginModal,
    NoDataAvailable,
    ModalCarousel,
    ProviderStoreDetailsResponsive,
  },
  data() {
    return {
      weekDays: ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'],
      toggleTimings: false,
      reviewIndex: 0,
      showPopup: false,
      mapRoute: '',
      searchQuery: '',
      addressInfo: [],
      itemView: 'grid-view',
      storeList: {},
      filteredStore: [],
      storeSlotInfo: [],
      optionsLoaded: {
        product: false,
        menu: false,
        overview: false,
        photos: false,
        reviews: false,
      },
      providerId: '',
      filterOptions: {
        selectedQuickFilter: '',
        quickFilters: [
          { text: 'All', value: '' },
          { text: 'Non Veg', value: 'non-veg' },
          { text: 'Pure Veg', value: 'pure-veg' },
        ],
        selectedCategory: '',
        categoryFilters: [],
        showPriceRange: true,
        priceRange: '',
      },
      itemViewOptions: [
        { icon: 'GridIcon', value: 'grid-view' },
        { icon: 'ListIcon', value: 'list-view' },
      ],
      startLocation: null,
      userOffers: [],
    }
  },
  computed: {
    termsConditions() {
      if (!this.storeList) return ''
      return `/user/provider/terms-conditions/${this.$route.params.serviceName}/${this.providerId}`
    },
    that() {
      return this
    },
    getUserInfo() {
      if (!window.SuperApp.getters.userInfo()) return {}
      return window.SuperApp.getters.userInfo()
    },
    isLoggedIn() {
      return isUserLoggedIn()
    },
    isFoodCategory() {
      if (!this.storeList) return false
      if (!this.storeList.storetype) return false
      if (this.storeList.storetype.category === 'FOOD') return true
      return false
    },
    cartItems() {
      return this.$store.state['app-ecommerce'].cartItems
    },
    lastReview() {
      if (!this.storeList) return null
      if (!this.storeList.reviews) return null
      return this.storeList.reviews[this.reviewIndex]
    },
    mainPhoto() {
      if (!this.storeList) return null
      if (!this.storeList.photos) return null
      if (this.storeList.photos.find(x => x.is_main)) {
        return this.storeList.photos.find(x => x.is_main).photo
      }
      return null
    },
    otherPhotos() {
      if (!this.storeList) return []
      if (!this.storeList.photos) return []
      let allPhotoInfo = this.storeList.photos.filter(x => x.is_main === 0)
      allPhotoInfo = allPhotoInfo.concat(
        this.storeList.provider_service.map(x => ({
          photo: x.main_service ? x.main_service.picture : '',
        })),
      )
      return allPhotoInfo.filter(x => x.photo)
    },
    allPhotos() {
      if (!this.storeList) return []
      if (!this.storeList.photos) return []
      return this.storeList.photos.map(x => x.photo)
    },
    storeTimings() {
      if (!this.storeList) return null
      if (!this.storeList.timings) return null
      return this.storeList.timings.find(x => x.day === this.currentDay)
    },
    storeCurrentTimings() {
      if (!this.storeList) return null
      if (!this.storeList.timings) return null
      if (this.storeTimings) return this.storeTimings
      return this.storeList.timings[0]
    },
    currentDay() {
      return moment().format('ddd').toUpperCase()
    },
  },
  watch: {
    searchQuery: {
      handler() {
        this.beginSearch()
      },
    },
  },
  setup() {
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    return {
      mqShallShowLeftSidebar,
    }
  },
  mounted() {
    this.init()
  },
  destroyed() {
    document.getElementById('app').classList.remove('store-web-new')
  },
  methods: {
    isEmpty,
    getServiceDescription(product) {
      return product.description ? product.description : product.main_service.description
    },
    getText(text) {
      return text.slice(0, 50)
    },
    canShowLabel(text) {
      return text && `${text}`.length > 50
    },
    storeTimingData(day) {
      const isExist = this.storeList.timings.find(x => x.day === day)
      if (isExist) {
        return isExist
      }
      return null
    },
    goBack() {
      window.history.back()
    },
    formatDate(date) {
      return moment(date).format('YYYY-MM-DD HH:mm A')
    },
    storeCurrentTimingsInfo() {
      if (!this.storeList) return null
      if (!this.storeList.timings) return null
      if (this.storeTimings) return this.storeTimings
      return this.storeList.timings[0]
    },
    prevReview() {
      if (!this.storeList) return null
      if (!this.storeList.reviews) return null
      if (this.reviewIndex > 0) this.reviewIndex -= 1
      return null
    },
    nextReview() {
      if (!this.storeList) return null
      if (!this.storeList.reviews) return null
      if (this.reviewIndex < this.storeList.reviews.length - 1) this.reviewIndex += 1
      return null
    },
    resetData() {
      this.reviewIndex = 0
      this.optionsLoaded = {
        product: false,
        menu: false,
        overview: false,
        photos: false,
        reviews: false,
      }
      this.filteredStore = []
      this.storeSlotInfo = []
      this.storeList = {}
      this.filterOptions = {
        selectedQuickFilter: '',
        quickFilters: [
          { text: 'All', value: '' },
          { text: 'Non Veg', value: 'non-veg' },
          { text: 'Pure Veg', value: 'pure-veg' },
        ],
        selectedCategory: '',
        categoryFilters: [],
        showPriceRange: true,
        priceRange: '',
      }
      this.searchQuery = ''
      this.addressInfo = []
    },
    init() {
      document.getElementById('app').classList.add('store-web-new')
      const { userAddressInfo } = useUserUi()
      showLoader()
      if (isUserLoggedIn()) {
        userAddressInfo()
          .then(({ data }) => {
            if (data.statusCode === '200') {
              this.addressInfo = data.responseData
            }
          })
          .catch(error => {
            showErrorNotification(this, error)
          })
      }
      this.getStoreList()
    },
    goToSocial(link, target) {
      window.open(link, target)
    },
    openStoreMap() {
      window.open(`https://maps.google.com/?q=${this.storeList.latitude},${this.storeList.longitude}`)
    },
    openPhone() {
      window.open(`tel:+${this.storeList.country_code}${this.storeList.mobile}`)
    },
    priceRangeChange(changeValue) {
      this.filterOptions.priceRange = changeValue
      this.getStoreList()
    },
    addItemToCart(product) {
      if (this.storeList.shopstatus !== 'OPEN') {
        this.showShopClosed()
        return
      }
      if (!isUserLoggedIn()) {
        this.$refs.loginModal.showModal()
        return
      }
      if (this.cartItems.find(x => x.provider_id !== product.provider_id)) {
        this.$swal({
          title: this.$t('Items already in cart'),
          text: this.$t('Your cart contains items from another store. Would you like to reset your cart for adding items from this store?'),
          iconHtml: this.$helpers.swalIcon('notification.png'),
          showCancelButton: true,
          confirmButtonText: 'Yes, start refresh',
          cancelButtonText: this.$t('No'),
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-danger ml-1',
            icon: 'border-0',
          },
          buttonsStyling: false,
        }).then(async result => {
          if (result.value) {
            const { removeStoreCart } = useUserUi()
            await removeStoreCart()
            this.makeCart(product)
          }
        })
      } else {
        this.makeCart(product)
      }
    },
    makeCart(product) {
      let isRemove = false
      let formData = new FormData()
      formData.append('item_id', product.id)
      const cartProduct = this.cartItems ? this.cartItems.find(x => x.provider_service_id === product.id) : null
      if (this.addedProduct(product) && this.addedProduct(product).quantity === 0) {
        if (cartProduct) {
          isRemove = true
        }
      }
      formData.append('qty', this.addedProduct(product) ? this.addedProduct(product).quantity : 1)
      if (this.cartItems && this.cartItems.length > 0) {
        if (cartProduct) {
          formData.append('repeat', 1)
        }
      } else {
        formData.append('repeat', 0)
      }
      if (cartProduct) {
        if (isRemove) {
          formData = new FormData()
        }
        formData.append('cart_id', cartProduct.id)
      }
      showLoader()
      this.$store
        .dispatch(`app-ecommerce/${isRemove ? 'removeServiceQuantity' : 'updateServiceQuantity'}`, formData)
        .then(response => {
          if (response.data.statusCode === '200') {
            const items = response.data.responseData.carts
            this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', items.length)
            this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS', items)
            showSuccessNotification(this, 'Cart updated successfully')
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    getStoreList() {
      showLoader()
      const { userProviderServiceDetail } = useUserUi()
      let extraFilters = ''
      const selectedUserAddress = window.SuperApp.actions.selectedUserAddress()
      const isAddressExist = this.$store.state['app-user'].userAddressList.find(x => x.id === selectedUserAddress)
      if (isAddressExist) {
        extraFilters += `&latitude=${isAddressExist.latitude}&longitude=${isAddressExist.longitude}`
      } else if (localStorage.userSelectedAddressObj) {
        const localAddress = JSON.parse(localStorage.userSelectedAddressObj)
        extraFilters += `&latitude=${localAddress.latitude}&longitude=${localAddress.longitude}`
      }
      userProviderServiceDetail({
        query: this.$route.params.serviceName,
        filters: extraFilters,
      })
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.storeList = data.responseData
            this.providerId = this.storeList.id
            if (this.storeList.provider_service) {
              this.storeList.provider_service.forEach(item => {
                if (!item.main_service) {
                  // eslint-disable-next-line no-param-reassign
                  item.main_service = {}
                }
              })
            }
            if (this.storeList.timings && this.storeList.timings[0] && this.storeList.timings[0].day === 'ALL') {
              this.storeList.timings = this.weekDays.map(x => ({
                day: x,
                end_time: this.storeList.timings[0].end_time,
                start_time: this.storeList.timings[0].start_time,
                provider_id: this.storeList.timings[0].provider_id,
              }))
            }
            window.storeList = data.responseData
            if (this.storeList.provider_service && this.storeList.provider_service.length > 0) {
              this.optionsLoaded.products = true
              this.storeList.provider_service = this.storeList.provider_service.map(x => ({
                ...x,
                showFull: false,
              }))
            }
            if (this.storeList.overviews) {
              this.optionsLoaded.overview = true
            }
            if (this.storeList.reviews && this.storeList.reviews.length > 0) {
              this.optionsLoaded.reviews = true
            }
            if (this.storeList.photos && this.storeList.photos.filter(x => x.is_menu === 0).length > 0) {
              this.optionsLoaded.photos = true
            }
            if (this.storeList.photos && this.storeList.photos.filter(x => x.is_menu === 1).length > 0) {
              this.optionsLoaded.menu = true
            }
            this.beginSearch()
            this.mapRoute = `https://maps.google.com/maps?q=${this.storeList.latitude}, ${this.storeList.longitude}&z=15&output=embed`
            // this.startLocation = new window.google.maps.LatLng(this.storeList.latitude, this.storeList.longitude)
            // window.gmapData = this.$refs.gmapRef
            // setTimeout(() => {
            //   if (window.gmapData && window.gmapData.$mapObject) {
            //     window.gmapData.$mapObject.setZoom(14)
            //   }
            // }, 1000)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    isAddedToCart(product) {
      return this.cartItems.find(x => x.provider_service_id === product.id)
    },
    addedProduct(product) {
      return this.cartItems.find(x => x.provider_service_id === product.id)
    },
    beginSearch() {
      if (this.searchQuery) {
        this.filteredStore = this.storeList.provider_service.filter(x => {
            const name = !isEmpty(x.title) ? x.title : x.main_service.service_name
            return x.main_service && name.toLowerCase().includes(this.searchQuery.toLowerCase())
        })
      } else {
        this.filteredStore = this.storeList.provider_service
      }
    },
    copyUrl() {
      if (window.SuperApp.helper.copyUrl()) {
        showSuccessNotification(this, 'Link copied successfully')
      } else {
        showDangerNotification(this, 'Failed to copy link')
      }
      window.SuperApp.actions.sharePage('MOM')
    },
    showShopClosed() {
      this.$swal({
        title: this.$t('Service is unavailable'),
        text: this.$t('Currently not accepting booking'),
        iconHtml: this.$helpers.swalIcon('schedule.png'),
        showCancelButton: false,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-danger ml-1',
          icon: 'border-0',
        },
        buttonsStyling: false,
      })
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/pages/app-ecommerce.scss';
@import '~@core/scss/base/store-details.scss';
</style>

<style lang="scss">
.user-login-required.apps-service-provider {
  .navbar {
    display: none !important;
  }
  .horizontal-layout.mobile-view-class.navbar-floating:not(.blank-page) .app-content {
    padding-top: 0 !important;
  }
  .horizontal-layout.navbar-floating:not(.blank-page) .app-content {
    padding-top: 0 !important;
  }
  .store-image-section-height {
    margin-top: 0 !important;
  }
  .t_and_c_prefix_text {
    display: none;
  }
}
</style>
<style lang="scss" scoped>
.wrap-store-review .reviews:last-child {
  border-bottom: 0 !important;
}

.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}

.result-toggler {
  height: 40px;
}

.ecommerce-application .grid-view .ecommerce-card .item-img {
  justify-content: center;
  padding-top: 0;
}

.ecommerce-application .list-view .ecommerce-card .item-img img {
  border-top-right-radius: 0;
}

.list-view .pt-pb {
  padding-bottom: 1rem !important;
  padding-top: 1rem !important;
}

.user-provider-store-details .ecommerce-application .grid-view {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
</style>

<style scoped>
@media only screen and (max-width: 767px) {
  .ecommerce-application .list-view .ecommerce-card .item-img {
    padding-top: 0;
    padding-bottom: 0;
  }

  .list-view .pt-pb {
    padding-top: 0 !important;
  }
}
</style>

<style>
@media only screen and (max-width: 767px) {
  .user-store-details .store-padding {
    padding-top: 10px;
  }

  .store-ui-landing .wrap-sidebar-filters .card {
    box-shadow: none;
  }

  .store-ui {
    padding-left: 10px;
    padding-right: 10px;
  }
}
</style>

<style>
@media only screen and (max-width: 767px) {
  .store-ui .store-image-section-height {
    height: auto;
    margin-top: 10px;
  }

  .store-ui .mobile-pad-0 {
    padding: 0;
  }

  .store-ui .wrap-grid-info {
    margin-top: 10px;
  }

  .store-ui .address-lines p {
    font-size: 15px;
  }

  .store-ui .wrap-address-line-last {
    display: block !important;
  }

  .store-ui .social-icon-height {
    height: 28px;
  }

  .store-ui .wrap-store-extra-description {
    padding: 15px;
  }

  .store-ui .wrap-book-a-table .wrap-store-options {
    width: 100% !important;
  }

  .store-ui .close-filter-button {
    display: block;
  }
}
.left-image {
  height: 600px;
  width: 100%;
}
.right-side-images {
  width: 100%;
  height: 298px;
}
.grid-row-gap-5 {
  grid-row-gap: 5px;
}
.pl-5px {
  padding-left: 5px !important;
}
</style>

<style scoped>
.store_title {
  font-size: 2rem;
  font-weight: 900;
  color: #000;
  text-transform: uppercase;
}
.store_location {
  font-size: 18px;
}
.store_phone {
  font-size: 18px;
  margin-top: 8px;
}
.main_card {
  border-radius: 10px;
  background-color: #f3f3f3;
}
.tab_card {
  border-radius: 10px;
  background-color: #f3f3f3;
}
.profile_page_label_icon {
  height: 30px !important;
}
.border-radius-10 {
  border-radius: 10px;
}
.store-logo {
  border-radius: 50%;
  height: 80px;
  width: 80px;
}
@media screen and (min-width: 768px) {
  .profile_page_label_icon {
    height: 40px !important;
  }
}
</style>