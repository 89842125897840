<template>
  <div class="user-provider-store-details user-store-details-responsive">
    <div
      v-if="that && that.storeList"
      style="height: inherit"
      class="user-store-details store-ui p-0"
    >
      <div class="mobile-pad-0 d-flex flex-wrap">
        <div class="store-image-section-height">
          <div class="store-main-image d-flex justify-content-center align-items-center overflow-hidden p-0 w-100 relative">
            <!-- <b-img
              class="back-icon-image cursor-pointer"
              style="height: 35px"
              :src="require('@/assets/images/app-icons/back.png')"
              @click="that.goBack"
            />
            <b-img
              class="share-icon-image cursor-pointer"
              style="height: 35px"
              :src="require('@/assets/images/app-icons/Share.png')"
              @click="that.copyUrl"
            /> -->
            <v-zoomer
              :zoomed.sync="$superApp.zoomed"
              :mouse-wheel-to-zoom.sync="$superApp.mouseWheelToZoom"
            >
              <b-img
                class="w-100 h-100 img-transition cursor-pointer"
                :src="that.mainPhoto ? $helpers.imageHelper(that.mainPhoto) : require('@/assets/images/app-images/banner-store-image.webp')"
                @click="that.showPopup = true"
                @error="$helpers.imageError"
              />
            </v-zoomer>
          </div>
        </div>
      </div>
      <div class="px-2">
        <div class="wrap-ratings d-flex justify-content-between align-items-center">
          <div>
            <p class="fs-1p125rem store-title-info text-left font-weight-bolder text-uppercase d-flex align-items-center">
              <b-img v-if="that.storeList.picture" :src="$helpers.imageHelper(that.storeList.picture)" @error="$helpers.imageError" fluid class="store-logo-m mr-1" />
              <span>{{ that.storeList.company_name }}</span>
            </p>
          </div>
          <div>
            <b-img
              :class="{ 'gray-scale-icon': !that.storeList.rating || that.storeList.rating <= 0 }"
              style="height: 25px"
              :src="require('@/assets/images/app-icons/rating.png')"
            />
            {{ that.storeList.rating }}
          </div>
        </div>
        <p
          class="mb-1"
        >
          {{ $t('Service is available at') }}: <b-button variant="outline-primary" size="sm" class="ml-1">{{ $t('Provider') }}</b-button>
        </p>
        <div
          v-if="that.storeList.current_location"
          class="d-flex wrapper-address-line address-lines mt-0 cursor-pointer"
          @click="that.openStoreMap"
        >
          <b-img
            style="height: 25px"
            :src="`${$apiUrl}/images/icons/location.svg`"
          />
          <p class="mb-0 ml-1">
            {{ that.storeList.current_location }}
          </p>
        </div>
        <div
          class="wrapper-address-line address-lines mt-0"
        >
          <div class="d-flex">
            <b-img
              style="height: 25px"
              :src="require('@/assets/images/app-icons/Timing.png')"
            />
            <div
              style="position: relative;"
              class="w-100 d-flex align-items-center"
              @click="that.toggleTimings = !that.toggleTimings"
            >
              <feather-icon
                style="position: absolute; right: 10px;"
                :icon="that.toggleTimings ? 'ChevronUpIcon' : 'ChevronDownIcon'"
                size="15"
              />
              <p
                v-if="that.storeTimings"
                class="mb-0 ml-2"
              >
                Today: {{ that.storeTimings.start_time.slice(0, 5) }} - {{ that.storeTimings.end_time.slice(0, 5) }}
              </p>
              <p
                v-else
                class="mb-0 ml-2"
              >
                Today: Closed
              </p>
            </div>
          </div>
          <template v-if="that.toggleTimings">
            <table class="table table-borderless">
              <tbody>
                <tr
                  v-for="(data, index) of that.weekDays"
                  :key="index"
                  :class="{ 'font-weight-bold' : that.currentDay === data }"
                >
                  <td class="py-0 pl-4">
                    {{ data }}
                  </td>
                  <td
                    v-if="that.storeTimingData(data)"
                    class="py-0 text-right"
                  >
                    {{ that.storeTimingData(data).start_time.slice(0, 5) }} - {{ that.storeTimingData(data).end_time.slice(0, 5) }}
                  </td>
                  <td
                    v-else
                    class="py-0 text-right"
                  >
                    Closed
                  </td>
                </tr>
              </tbody>
            </table>
          </template>
        </div>
        <div
          v-if="that.storeList.mobile"
          class="d-flex wrapper-address-line address-lines mt-0 cursor-pointer"
          @click="that.openPhone"
        >
          <b-img
            style="height: 25px"
            :src="require('@/assets/images/app-icons/Call.png')"
          />
          <p class="mb-0 ml-1">
            {{ that.storeList.mobile }}
          </p>
        </div>
        <div class="d-flex address-lines mt-1 justify-content-between">
          <b-row class="d-flex align-content-center flex-wrap pl-1">
            <b-col v-if="that.storeList.facebook" cols="auto" class="px-0 pr-social-icons">
              <b-img
                class="mr-1 social-icon-height cursor-pointer"
                :src="require('@/assets/images/app-icons/Facebook.png')"
                @click="that.goToSocial(`${that.storeList.facebook}`, '_BLANK')"
              />
            </b-col>
            <b-col v-if="that.storeList.instagram" cols="auto" class="px-0 pr-social-icons">
              <b-img
                class="mr-1 social-icon-height cursor-pointer"
                :src="require('@/assets/images/app-icons/Instagram.png')"
                @click="that.goToSocial(`${that.storeList.instagram}`, '_BLANK')"
              />
            </b-col>
            <b-col v-if="that.storeList.linkedin" cols="auto" class="px-0 pr-social-icons">
              <b-img
                class="mr-1 social-icon-height cursor-pointer"
                :src="require('@/assets/images/app-icons/LinkedIn.png')"
                @click="that.goToSocial(`${that.storeList.linkedin}`, '_BLANK')"
              />
            </b-col>
            <b-col v-if="that.storeList.telegram" cols="auto" class="px-0 pr-social-icons">
              <b-img
                class="mr-1 social-icon-height cursor-pointer"
                :src="require('@/assets/images/app-icons/Telegram.png')"
                @click="that.goToSocial(`${that.storeList.telegram}`, '_BLANK')"
              />
            </b-col>
            <b-col v-if="that.storeList.twitter" cols="auto" class="px-0 pr-social-icons">
              <b-img
                class="mr-1 social-icon-height cursor-pointer"
                :src="require('@/assets/images/app-icons/Twitter.png')"
                @click="that.goToSocial(`${that.storeList.twitter}`, '_BLANK')"
              />
            </b-col>
            <b-col v-if="that.storeList.whatsapp" cols="auto" class="px-0 pr-social-icons">
              <b-img
                class="mr-1 social-icon-height cursor-pointer"
                :src="require('@/assets/images/app-icons/WhatsApp.png')"
                @click="that.goToSocial(`${that.storeList.whatsapp}`, '_BLANK')"
              />
            </b-col>
          </b-row>
          <b-row class="d-flex align-content-center flex-wrap pl-1">
            <b-col cols="auto" class="px-0 pr-social-icons">
              <b-img
                class="social-icon-height cursor-pointer"
                :src="require('@/assets/images/app-icons/Share.png')"
                @click="that.copyUrl"
              />
            </b-col>
          </b-row>
        </div>
        <div @click="seeOrderNow" class="cp wrap-order-now d-flex justify-content-between align-items-end">
          <p class="store-title-info text-left font-weight-bolder text-uppercase">
            {{ $t('Book Now') }}
          </p>
          <p
            class="cursor-pointer"
            style="margin-bottom: 6px"
          >
            {{ $t('See services') }}
          </p>
        </div>
        <div
          v-if="that.optionsLoaded.overview && that.storeList.overviews"
          class="cp wrap-overview"
          @click="seeOverview"
        >
          <p
            class="store-title-info text-left font-weight-bolder text-uppercase"
          >
            {{ $t('Overview') }} <feather-icon
              :icon="toggleOverview ? 'ChevronUpIcon' : 'ChevronDownIcon'"
              size="15"
            />
          </p>
          <!-- eslint-disable vue/no-v-html -->
          <p
            v-if="toggleOverview"
            v-html="that.storeList.overviews.overview"
          />
          <!--eslint-enable-->
        </div>
        <div class="menu-services">
          <div @click="seePhotos" class="cp d-flex justify-content-between align-items-end">
            <p class="store-title-info text-left font-weight-bolder text-uppercase">
              {{ $t('Menu & Photos') }}
            </p>
            <span
              style="margin-bottom: 6px"
            >{{ $t('See all') }} ({{ that.otherPhotos.length + (that.storeList.photos ? that.storeList.photos.filter(x => x.is_menu === 1).length : 0) }})</span>
          </div>
          <div
            v-if="that.storeList.photos"
            class="menus-and-photos"
          >
            <b-img
              v-for="(item, index) in that.otherPhotos"
              :key="index"
              data-hide="true"
              class="store-photos-tab store-shadow img-transition cursor-pointer"
              :src="$helpers.imageHelper(item.photo)"
              @click="that.showPopup = true"
              @error="$helpers.imageError"
            />
            <b-img
              v-for="(item, index) in that.storeList.photos.filter(x => x.is_menu === 1)"
              :key="index"
              data-hide="true"
              class="store-photos-tab store-shadow img-transition cursor-pointer"
              :src="$helpers.imageHelper(item.photo)"
              @click="that.showPopup = true"
            />
          </div>
        </div>
        <div class="wrap-order-now d-flex justify-content-between align-items-end">
          <p class="store-title-info text-left font-weight-bolder text-uppercase">
            {{ $t('Reviews') }}
          </p>
          <p
            class="cursor-pointer"
            style="margin-bottom: 6px"
            @click="seeReviewsNow"
          >
            {{ $t('See all') }}
          </p>
        </div>
      </div>
    </div>
    <b-modal
      id="reviewsModal"
      v-model="seeReviewModal"
      :no-close-on-backdrop="true"
      class="ecommerce-application"
      :title="$t('Review & Ratings')"
      size="lg"
      hide-footer
      @hidden="seeReviewModal = false"
    >
      <no-data-available
        v-if="!that.optionsLoaded.reviews"
        :type="'reviews'"
      />
      <div v-if="that.optionsLoaded.reviews">
        <div
          v-for="(item, index) of that.storeList.reviews"
          :key="index"
          class="col-md-12 p-0 d-flex mb-1"
          :class="{ 'border-bottom': index < that.storeList.reviews.length - 1 }"
        >
          <b-media class="p-0 align-items-center">
            <template #aside>
              <b-avatar />
            </template>
          </b-media>
          <div class="review-wrapper-store">
            <h6 class="m-0">
              {{ item.user ? `${item.user.first_name} ${item.user.last_name}` : '' }}
            </h6>
            <p
              v-if="item.request"
              class="mb-0 font-weight-bolder"
            >
              ({{ $t('Service No: ') }} {{ item.request.booking_id }})
            </p>
            <p class="m-0">
              {{ item.review }}
            </p>
            <div class="mb-0">
              <ul
                class="unstyled-list list-inline"
                style="margin-bottom: 5px"
              >
                <li
                  v-for="star in 5"
                  :key="star"
                  class="ratings-list-item"
                  :class="{ 'ml-25': star - 1 }"
                >
                  <feather-icon
                    icon="StarIcon"
                    size="16"
                    :class="[{ 'fill-current': star <= item.rate }, star <= item.rate ? 'theme-color' : 'text-muted']"
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="d-flex">
        <div class="col-md-12 store-shadow wrap-store-review mt-3">
          <post-review />
        </div>
      </div> -->
    </b-modal>
    <b-modal
      id="photosModal"
      v-model="seePhotoModal"
      :no-close-on-backdrop="true"
      class="ecommerce-application"
      :title="$t('Photos')"
      size="lg"
      hide-footer
      @hidden="seePhotoModal = false"
    >
      <no-data-available
        v-if="!that.optionsLoaded.photos && !that.optionsLoaded.menu"
        :type="'menu'"
      />
      <div v-if="that.optionsLoaded.photos || that.optionsLoaded.menu">
        <div
          v-if="that.storeList.photos"
          class="menus-and-photos"
        >
          <b-img
            v-for="(item, index) in that.storeList.photos"
            :key="index"
            class="store-photos-tab store-shadow img-transition cursor-pointer"
            :src="$helpers.imageHelper(item.photo)"
            @click="that.showPopup = true"
          />
        </div>
      </div>
    </b-modal>
    <b-modal
      id="orderNowModal"
      v-model="seeOrderMenuModal"
      :no-close-on-backdrop="true"
      class="ecommerce-application"
      :title="$t('Check the list')"
      size="lg"
      hide-footer
      @hidden="seeOrderMenuModal = false"
    >
      <div class="wrap-online-order-section">
        <no-data-available
          v-if="!that.optionsLoaded.products"
          :type="'product'"
        />
        <div
          v-if="that.optionsLoaded.products"
          class="col-md-12 px-0"
        >
          <section id="ecommerce-header">
            <div class="row">
              <div
                v-if="that.userOffers.length > 0"
                class="promocodes-wrapper d-grid grid-mobile-columns"
              >
                <div
                  v-for="(item, index) of that.userOffers"
                  :key="index"
                  class="grid-wrapper theme-bg-color text-white py-1 px-2 cursor-pointer"
                  @click="that.goToPromocode(item)"
                >
                  {{ $t('Use promo code') }} {{ item.promo_code }} {{ $t('to get') }} {{ item.promo_description }}
                </div>
              </div>
              <div class="col-sm-12 p-0">
                <div class="col-md-12">
                  <div class="ecommerce-header-items">
                    <div class="result-toggler d-flex justify-content-between align-item-center">
                      <!-- <feather-icon
                      icon="MenuIcon"
                      class="d-block d-lg-none"
                      size="21"
                      @click="that.mqShallShowLeftSidebar = true"
                    /> -->
                      <div class="search-results">
                        {{ $t('Showing') }} {{ that.filteredStore.length }} {{ $t('of') }} {{ that.storeList.provider_service ? that.storeList.provider_service.length : 0 }} {{ $t('results') }}
                      </div>
                      <div class="view-options d-flex">
                        <cart-dropdown
                          v-if="isLoggedIn"
                          class="store-cart-option-button"
                        />
                        <!-- Item View Radio Button Group  -->
                        <b-form-radio-group
                          v-model="that.itemView"
                          class="ml-1 list item-view-radio-group"
                          buttons
                          size="sm"
                          button-variant="outline-primary"
                        >
                          <b-form-radio
                            v-for="option in that.itemViewOptions.filter(x => x.icon == 'ListIcon')"
                            :key="option.value"
                            :value="option.value"
                          >
                            <feather-icon
                              :icon="option.icon"
                              size="18"
                            />
                          </b-form-radio>
                        </b-form-radio-group>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div class="body-content-overlay" />

          <div class="ecommerce-searchbar mt-1 mb-1">
            <b-row>
              <b-col cols="12">
                <b-input-group class="input-group-merge">
                  <b-form-input
                    v-model="that.searchQuery"
                    placeholder="Search..."
                    class="search-product"
                    @keyup="that.beginSearch"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      icon="SearchIcon"
                      class="text-muted"
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-col>
            </b-row>
          </div>

          <section :class="that.itemView">
            <b-card
              v-for="product in that.filteredStore"
              :key="product.id"
              class="ecommerce-card pt-pb"
              no-body
            >
              <div class="item-img text-center py-1">
                <b-link class="w-100">
                  <figure
                    @mouseenter="$superApp.helper.zoomMagic"
                    @mouseout="$superApp.helper.zoomOut"
                  >
                    <v-zoomer
                      :zoomed.sync="$superApp.zoomed"
                      :mouse-wheel-to-zoom.sync="$superApp.mouseWheelToZoom"
                    >
                      <b-img
                        :alt="!isEmpty(product.title) ? product.title : product.main_service.service_name"
                        fluid
                        class="card-img-top"
                        :src="$helpers.imageHelper(product.main_service.picture)"
                        @error="$helpers.imageError"
                      />
                    </v-zoomer>
                  </figure>
                </b-link>
              </div>

              <div class="d-flex flex-wrap py-1">
                <!-- Product Details -->
                <b-card-body class="py-0 px-1">
                  <h6 class="item-name">
                    <b-link class="text-body">
                      {{ !isEmpty(product.title) ? product.title : product.main_service.service_name }}
                    </b-link>
                    <b-card-text class="item-company">
                      {{ $t('By') }} {{ that.storeList.company_name }}
                      <b-link class="ml-25">
                        {{ that.storeList.first_name }} {{ that.storeList.last_name }}
                      </b-link>
                    </b-card-text>
                  </h6>
                  <b-card-text
                    v-if="that.getServiceDescription(product)"
                    class="item-description d-block auto-text fs-14px"
                    :class="{ 'mb-0': that.itemView === 'list-view' }"
                  >
                    {{ product.showFull ? that.getServiceDescription(product) : that.getText(that.getServiceDescription(product)) }}
                    <span
                      v-if="that.canShowLabel(that.getServiceDescription(product))"
                      class="small font-weight-bold text-right"
                      style="word-break: keep-all !important;margin-top: 4px;float: right"
                      @click="product.showFull = !product.showFull"
                    >
                      <feather-icon
                        :icon="product.showFull ? 'ChevronUpIcon' : 'ChevronDownIcon'"
                        class="cursor-pointer"
                        size="15"
                      />
                    </span>
                  </b-card-text>
                </b-card-body>
                <div class="card-body d-flex align-items-center py-0 mb-1 px-1">
                  <h6 class="item-price">
                    {{ that.storeList.currency_symbol }} {{ product.base_fare }}
                  </h6>
                  <div
                    class="item-options text-center mb-0 list-view-manage cart-item-qty"
                  >
                    <b-form-spinbutton
                      v-if="that.isAddedToCart(product)"
                      v-model="that.addedProduct(product).quantity"
                      min="0"
                      size="sm"
                      @change="that.addItemToCart(product)"
                    />
                    <b-button
                      v-if="!that.isAddedToCart(product)"
                      variant="primary"
                      tag="a"
                      class="btn-cart"
                      @click="that.addItemToCart(product)"
                    >
                      <span class="text-uppercase">{{ that.isAddedToCart(product) ? 'Added to cart' : 'Add' }}</span>
                    </b-button>
                  </div>
                </div>
              </div>
            </b-card>
          </section>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import CartDropdown from '@/@core/layouts/components/app-navbar/components/CartDropdown.vue'
import { isUserLoggedIn } from '@/auth/utils'
import NoDataAvailable from './shared/NoDataAvailable.vue'
import { isEmpty } from '@/utilities'

export default {
  components: {
    NoDataAvailable,
    CartDropdown,
  },
  props: {
    // eslint-disable-next-line vue/require-prop-types
    that: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      seeOrderMenuModal: false,
      seeReviewModal: false,
      seePhotoModal: false,
      toggleOverview: false,
    }
  },
  computed: {
    isLoggedIn() {
      return isUserLoggedIn()
    },
  },
  methods: {
    isEmpty,
    seeOrderNow() {
      this.that.itemView = 'list-view'
      this.seeOrderMenuModal = true
    },
    seeReviewsNow() {
      this.seeReviewModal = true
    },
    seeOverview() {
      this.toggleOverview = !this.toggleOverview
    },
    seePhotos() {
      this.seePhotoModal = true
    },
  },
}
</script>

<style lang="scss">
[dir] body .horizontal-layout.mobile-view-class.navbar-floating:not(.blank-page) .app-content {
  padding-top: 52px !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

@import '~@core/scss/base/store-details-responsive.scss';

.store-logo-m{border-radius: 50%; height: 25px; width: 25px;}

</style>
